
.ql-override .ql-container {
  font-family: unset;
  font-size: unset;
}

.ql-readonly .ql-editor {
  padding: 0px 0px;
}

.ql-readonly .ql-container.ql-snow {
  border: 0px;
}

.ql-editor .ql-video {
  display: block;
  width: 800px;
  height: 450px;
/*
  width: 900px;
  height: 507px;
*/
}
